import lodash from 'lodash';

export function checkAccessType(user, modulePermissions) {
  if (!user || !modulePermissions) return null;
  const accessType = {view: false, edit: false, delete: false, translate: false};
  user.permissions.forEach((perm) => {
    const type = perm.split('_')[0];
    modulePermissions.forEach((mPerm) => {
      if (perm === mPerm && type === 'view') accessType.view = true;
      if (perm === mPerm && type === 'edit') accessType.edit = true;
      if (perm === mPerm && type === 'delete') accessType.delete = true;
      if (perm === mPerm && type === 'translate') accessType.translate = true;
    });
  });
  return accessType;
}

export function checkAuthGroup(user, source) {
  if (!user || !source) return null;
  const filtered = [];
  source.forEach((item) => {
    if (item.authGroups) {
      item.authGroups.forEach((allowedGroup) => {
        user.groups.forEach((userGroup) => {
          if (allowedGroup === userGroup) filtered.push(item);
        });
      });
    } else {
      filtered.push(item);
    }
  });
  return lodash.uniq(filtered);
}

export function checkAuthPermissons(user, source) {
  if (!user || !source) return null;
  const filtered = [];
  source.forEach((item) => {
    if (item.authPerms) {
      item.authPerms.forEach((allowedPerm) => {
        user.permissions.forEach((userPerm) => {
          if (allowedPerm === userPerm) filtered.push(item);
        });
      });
    } else {
      filtered.push(item);
    }
  });
  return lodash.uniq(filtered);
}

export function checkPermissions(user, source) {
  if (!user || !source) return null;
  let filtered = [];
  const isArray = lodash.isArray(source);

  if (isArray) {
    filtered = checkAuthGroup(user, source);
    filtered = checkAuthPermissons(user, source);
  }

  return lodash.uniq(filtered);
}

export function flattenRoutes(user, routes) {
  if (!user || !routes) return null;
  let flattened = [];
  routes.map((topRoute) => {
    if (topRoute.path) flattened.push(topRoute);
    if (topRoute.routes) topRoute.routes.map((nestedRoute) => flattened.push(nestedRoute));
    return topRoute;
  });

  flattened = checkPermissions(user, flattened);

  return flattened;
}

// TODO G: the code here is very hard to understand, but it involves detecting if a route (or nested routes) from the main routeObject should show a lock icon or not.
export function generateSidenavRoutes(user, routes) {
  if (!user || !routes) return null;
  const flattened = flattenRoutes(user, routes);
  const flattenedRouteNames = flattened.map((item) => item.name);
  const filteredTopRoutes = [];

  routes.forEach((topRoute) => {
    let hasRoute = false;
    flattenedRouteNames.forEach((routeName) => {
      if (topRoute.name === routeName) {
        hasRoute = true;
      }
    });
    if (hasRoute) filteredTopRoutes.push(topRoute);
    if (!hasRoute && !topRoute.isGroup) {
      topRoute.locked = true;
      filteredTopRoutes.push(topRoute);
    } else {
      filteredTopRoutes.push(topRoute);
    }
  });

  filteredTopRoutes.map((topRoute) => {
    if (topRoute.routes) {
      topRoute.routes.map((route) => {
        route.locked = true;
        flattenedRouteNames.forEach((routeName) => {
          if (route.name === routeName) {
            route.locked = false;
            return route;
          }
        });
        return route;
      });
    }
    return topRoute;
  });

  return routes;
}

export const whitelist = [
  {
    email: 'george@ritualgym.com',
    roles: ['coach', 'admin'],
    groups: ['app', 'hq', 'sghv', 'sgrp', 'sgrr', 'sgor', 'brvn'],
    permissions: [
      'view_articles',
      'edit_article',
      'view_programs',
      'edit_program',
      'view_audio',
      'edit_audio',
      'view_movements',
      'edit_movement',
      'view_devtools',
    ],
  },
  {
    email: 'jaden@ritualgym.com',
    roles: ['coach', 'admin'],
    groups: ['app', 'hq', 'sghv', 'sgrp', 'sgrr', 'sgor', 'brvn'],
    permissions: [
      'view_articles',
      'edit_article',
      'view_programs',
      'edit_program',
      'view_audio',
      'edit_audio',
      'view_movements',
      'edit_movement',
      'view_devtools',
    ],
  },
  {
    email: 'tuanphunglk@gmail.com',
    roles: ['coach', 'admin'],
    groups: ['app', 'hq', 'sghv', 'sgrp', 'sgrr', 'sgor', 'brvn'],
    permissions: [
      'view_articles',
      'edit_article',
      'view_programs',
      'edit_program',
      'view_audio',
      'edit_audio',
      'view_movements',
      'edit_movement',
      'view_devtools',
    ],
  },
  {
    email: 'chris@mashcrate.com',
    roles: ['coach', 'admin'],
    groups: ['app', 'hq', 'sghv', 'sgrp', 'sgrr', 'sgor', 'brvn'],
    permissions: [
      'view_articles',
      'edit_article',
      'view_programs',
      'edit_program',
      'view_audio',
      'edit_audio',
      'view_movements',
      'edit_movement',
      'view_devtools',
    ],
  },
  {
    email: 'thach@mashcrate.com',
    roles: ['coach', 'admin'],
    groups: ['app', 'hq', 'sghv', 'sgrp', 'sgrr', 'sgor', 'brvn'],
    permissions: [
      'view_articles',
      'edit_article',
      'view_programs',
      'edit_program',
      'translate_program',
      'view_audio',
      'edit_audio',
      'view_movements',
      'edit_movement',
      'view_devtools',
    ],
  },
  {
    email: 'tvai.gr@gmail.com',
    roles: ['coach', 'admin'],
    groups: ['app', 'hq'],
    permissions: ['view_articles', 'view_programs', 'view_audio', 'translate_program', 'view_movements'],
  },
  {
    email: 'chris@ritualgym.com',
    roles: ['coach', 'admin'],
    groups: ['app', 'hq', 'sghv', 'sgrp', 'sgrr', 'sgor', 'brvn'],
    permissions: [
      'view_articles',
      'edit_article',
      'view_programs',
      'edit_program',
      'view_audio',
      'edit_audio',
      'view_movements',
      'edit_movement',
      'view_devtools',
    ],
  },
  {
    email: 'mervyn@ritualgym.com',
    roles: ['coach', 'admin'],
    groups: ['app', 'hq', 'sghv', 'sgrp', 'sgrr', 'sgor', 'brvn'],
    permissions: [
      'view_articles',
      'edit_article',
      'view_programs',
      'edit_program',
      'view_audio',
      'edit_audio',
      'view_movements',
      'edit_movement',
      'view_devtools',
    ],
  },
  {
    email: 'ethan@ritualgym.com',
    roles: ['coach', 'admin'],
    groups: ['app', 'hq', 'sghv', 'sgrp', 'sgrr', 'sgor', 'brvn'],
    permissions: [
      'view_articles',
      'edit_article',
      'view_programs',
      'edit_program',
      'view_audio',
      'edit_audio',
      'view_movements',
      'edit_movement',
      'view_devtools',
    ],
  },
  {
    email: 'norman@ritualgym.com',
    roles: ['coach', 'admin'],
    groups: ['app', 'hq', 'sghv', 'sgrp', 'sgrr', 'sgor', 'brvn'],
    permissions: [
      'view_articles',
      'edit_article',
      'view_programs',
      'edit_program',
      'view_audio',
      'edit_audio',
      'view_movements',
      'edit_movement',
      'view_devtools',
    ],
  },
  {
    email: 'simon@ritualgym.com',
    roles: ['coach', 'admin'],
    groups: ['app', 'hq', 'sghv', 'sgrp', 'sgrr', 'sgor', 'brvn'],
    permissions: [
      'view_articles',
      'edit_article',
      'view_programs',
      'edit_program',
      'view_audio',
      'edit_audio',
      'view_movements',
      'edit_movement',
      'view_devtools',
    ],
  },
  {
    email: 'ian@ritualgym.com',
    roles: ['coach', 'admin'],
    groups: ['app', 'hq', 'sghv', 'sgrp', 'sgrr', 'sgor', 'brvn'],
    permissions: [
      'view_articles',
      'edit_article',
      'view_programs',
      'edit_program',
      'view_audio',
      'edit_audio',
      'view_movements',
      'edit_movement',
      'view_devtools',
    ],
  },
  {
    email: 'ash@ritualgym.com',
    roles: ['coach', 'admin'],
    groups: ['app', 'hq', 'sghv', 'sgrp', 'sgrr', 'sgor', 'brvn'],
    permissions: [
      'view_articles',
      'edit_article',
      'view_programs',
      'edit_program',
      'view_audio',
      'edit_audio',
      'view_movements',
      'edit_movement',
      'view_devtools',
    ],
  },
  {
    email: 'ismail@ritualgym.com',
    roles: ['coach', 'admin'],
    groups: ['app', 'hq', 'sghv', 'sgrp', 'sgrr', 'sgor', 'brvn'],
    permissions: [
      'view_articles',
      'edit_article',
      'view_programs',
      'edit_program',
      'view_audio',
      'edit_audio',
      'view_movements',
      'edit_movement',
      'view_devtools',
    ],
  },
  {
    email: 'emily@ritualgym.com',
    roles: ['coach', 'admin'],
    groups: ['app', 'hq', 'sghv', 'sgrp', 'sgrr', 'sgor', 'brvn'],
    permissions: [
      'view_articles',
      'edit_article',
      'view_programs',
      'edit_program',
      'view_audio',
      'edit_audio',
      'view_movements',
      'edit_movement',
      'view_devtools',
    ],
  },
  {
    email: 'jillian@ritualgym.com',
    roles: ['coach', 'admin'],
    groups: ['app', 'hq', 'sghv', 'sgrp', 'sgrr', 'sgor', 'brvn'],
    permissions: [
      'view_articles',
      'edit_article',
      'view_programs',
      'edit_program',
      'view_audio',
      'edit_audio',
      'view_movements',
      'edit_movement',
      'view_devtools',
    ],
  },
  {
    email: 'charmaine@ritualgym.com',
    roles: ['coach', 'admin'],
    groups: ['app', 'hq', 'sghv', 'sgrp', 'sgrr', 'sgor', 'brvn'],
    permissions: [
      'view_articles',
      'edit_article',
      'view_programs',
      'edit_program',
      'view_audio',
      'edit_audio',
      'view_movements',
      'edit_movement',
      'view_devtools',
    ],
  },
  {
    email: 'darren@ritualgym.com',
    roles: ['coach', 'admin'],
    groups: ['app', 'hq', 'sghv', 'sgrp', 'sgrr', 'sgor', 'brvn'],
    permissions: [
      'view_articles',
      'edit_article',
      'view_programs',
      'edit_program',
      'view_audio',
      'edit_audio',
      'view_movements',
      'edit_movement',
      'view_devtools',
    ],
  },
  {
    email: 'khai@ritualgym.com',
    roles: ['coach', 'admin'],
    groups: ['app', 'hq', 'sghv', 'sgrp', 'sgrr', 'sgor', 'brvn'],
    permissions: [
      'view_articles',
      'edit_article',
      'view_programs',
      'edit_program',
      'view_audio',
      'edit_audio',
      'view_movements',
      'edit_movement',
      'view_devtools',
    ],
  },
  {
    email: 'patricia@ritualgym.com',
    roles: ['coach', 'admin'],
    groups: ['app', 'hq', 'sghv', 'sgrp', 'sgrr', 'sgor', 'brvn'],
    permissions: [
      'view_articles',
      'edit_article',
      'view_programs',
      'edit_program',
      'view_audio',
      'edit_audio',
      'view_movements',
      'edit_movement',
      'view_devtools',
    ],
  },
  {
    email: 'illovo@ritualgym.com',
    roles: ['coach', 'admin'],
    groups: ['app'],
    permissions: ['view_articles', 'view_programs', 'view_movements', 'view_audio'],
  },
  {
    email: 'vilanova@ritualgym.com',
    roles: ['coach', 'admin'],
    groups: ['app'],
    permissions: ['view_articles', 'view_programs', 'view_movements', 'view_audio'],
  },
  {
    email: 'bm.saopaulo@ritualgym.com',
    roles: ['manager'],
    groups: ['app'],
    permissions: ['view_articles', 'view_programs', 'translate_program', 'view_movements', 'view_audio'],
  },
  {
    email: 'dfalsi@gmail.com',
    roles: ['manager'],
    groups: ['app'],
    permissions: ['view_articles', 'view_programs', 'translate_program', 'view_movements', 'view_audio'],
  },
  {
    email: 'brooklin@ritualgym.com',
    roles: ['coach', 'admin'],
    groups: ['app'],
    permissions: ['view_articles', 'view_programs', 'view_movements', 'view_audio'],
  },
  {
    email: 'azca@ritualgym.com',
    roles: ['coach', 'admin'],
    groups: ['app'],
    permissions: ['view_articles', 'view_programs', 'view_movements', 'view_audio'],
  },
  {
    email: 'bm.azca@ritualgym.com',
    roles: ['manager'],
    groups: ['app'],
    permissions: ['view_articles', 'view_programs', 'translate_program', 'view_movements', 'view_audio'],
  },
  {
    email: 'fourways@ritualgym.com',
    roles: ['coach', 'admin'],
    groups: ['app'],
    permissions: ['view_articles', 'view_programs', 'view_movements', 'view_audio'],
  },
  {
    email: 'santamonica@ritualgym.com',
    roles: ['coach', 'admin'],
    groups: ['app'],
    permissions: ['view_articles', 'view_programs', 'view_movements', 'view_audio'],
  },
  {
    email: 'zurich@ritualgym.com',
    roles: ['coach', 'admin'],
    groups: ['app'],
    permissions: ['view_articles', 'view_programs', 'view_movements', 'view_audio'],
  },
  {
    email: 'hollandvillage@ritualgym.com',
    roles: ['coach', 'admin'],
    groups: ['app'],
    permissions: ['view_articles', 'view_programs', 'view_movements', 'view_audio'],
  },
  {
    email: 'rafflesplace@ritualgym.com',
    roles: ['coach', 'admin'],
    groups: ['app', 'hq', 'sghv', 'sgrp', 'sgrr', 'sgor', 'brvn'],
    permissions: ['view_articles', 'view_programs', 'view_movements', 'view_audio'],
  },
  {
    email: 'orchardroad@ritualgym.com',
    roles: ['coach', 'admin'],
    groups: ['app', 'hq', 'sghv', 'sgrp', 'sgrr', 'sgor', 'brvn'],
    permissions: ['view_articles', 'view_programs', 'view_movements', 'view_audio'],
  },
  {
    email: 'robinsonroad@ritualgym.com',
    roles: ['coach', 'admin'],
    groups: ['app'],
    permissions: ['view_articles', 'view_programs', 'view_movements'],
  },
  {
    email: 'nick@ritualgym.com',
    roles: ['coach', 'admin'],
    groups: ['app'],
    permissions: ['view_articles', 'view_programs', 'view_movements'],
  },
  {
    email: 'shanghai@ritualgym.com',
    roles: ['coach', 'admin'],
    groups: ['app'],
    permissions: ['view_articles', 'view_programs', 'view_movements', 'translate_program'],
  },
  {
    email: 'goya@ritualgym.com',
    roles: ['coach', 'admin'],
    groups: ['app'],
    permissions: ['view_articles', 'view_programs', 'view_movements'],
  },
  {
    email: 'marinadurancoach@gmail.com',
    roles: ['manager'],
    groups: ['app'],
    permissions: ['view_articles', 'view_programs', 'translate_program', 'view_movements'],
  },
  {
    email: 'joun@ritualgym.com',
    roles: ['manager'],
    groups: ['app'],
    permissions: ['view_articles', 'view_programs', 'translate_program', 'view_movements'],
  },
  {
    email: '82133458@qq.com',
    roles: ['manager'],
    groups: ['app'],
    permissions: ['view_articles', 'view_programs', 'translate_program', 'view_movements'],
  },
  {
    email: 'haojie.li1983@163.com',
    roles: ['manager'],
    groups: ['app'],
    permissions: ['view_articles', 'view_programs', 'translate_program', 'view_movements'],
  },
  {
    email: 'brooklin1@ritualgym.com',
    roles: ['coach', 'admin'],
    groups: ['app'],
    permissions: ['view_articles', 'view_programs', 'view_movements', 'view_audio'],
  },
];

export default whitelist;
